import React, { useEffect } from "react";
import useFetch from "../api/useFetch";
import usePost from '../api/usePost';
import { useState } from "react";
import Table from "./Table";
import Loading from "./Loading";
import { useParams, useNavigate } from 'react-router-dom';
import funcs from "../utils/utils";

const Payroll = ({notify}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { result: payroll, isLoading: payrollLoading, error: payrollError } = useFetch(`/payroll/${id}`, []);
  const tableHeader = [{ content: "Technician", size: "md", isLink: false}, { content: "Date", size: "md", isLink: false}, { content: "Client", size: "md", isLink: false}, { content: "Week", size: "md", isLink: false}, { content: "Year", size: "md", isLink: false}, { content: "Service Payout", size: "md", isLink: false}, { content: "Technician Tip", size: "md", isLink: false}];
  const [tableContent, setTableContent] = useState(null);
  const { postData } = usePost();

  const deletePayroll = async () => {
    if (window.confirm("Are you sure you want to delete this payroll?")) {
    const data = {payroll_id: id}
    const response = await postData('/delete_payroll', data);
    if (response) {
      notify("Payroll deleted");
      navigate("/payroll");
    }
  }
  else {
    return null;
  }
  };

  useEffect(() => {
    if (payroll !== null) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setTableContent(
        payroll.items.sort((a,b) => new Date(a.appointment.date_time) - new Date(b.appointment.date_time)).map((item) => [
          item.appointment.technician.person.first_name + " " + item.appointment.technician.person.last_name,
          funcs.displayDate(item.appointment.date_time),
          item.appointment.client.person ? item.appointment.client.person.first_name + " " + item.appointment.client.person.last_name : '',
          item.appointment.week,
          item.appointment.year,
          item.appointment.technician_payout,
          item.appointment.technician_tip,
        ])
      );
    }
  }, [payroll]);
  

  return (
    <>
        <div div className="list">
        <div className="title">
          Payroll
        </div>
        <div className="buttons">
          <div onClick={deletePayroll} className="button">
            <div>Delete</div>
          </div>
        </div>
        <br />
        <br />
        
        {payroll && 
        <>
        <center>
        <div>
          <b>Technician: </b>{payroll.payroll.contractor.person.first_name} {payroll.payroll.contractor.person.last_name}
        </div>
        <div>
          <b>Year: </b>{payroll.payroll.year}
        </div>
        <div>
          <b>Week: </b>{payroll.payroll.week}
        </div>
        <div>
          <b>Nb Appointments: </b>{payroll.payroll.appointments_nb}
        </div>
        <div>
          <b>Total Payout: </b>${payroll.payroll.amount}
        </div>
        </center>
        <br />
        <br />
        <Table tableHeader={tableHeader} tableContent={tableContent} />
        </>
        }
      {payrollLoading && <Loading />}
      {payrollError && <>{payrollError}</>}
      </div>
    </>
  );
};

export default Payroll;
